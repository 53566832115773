// anagram counter, increments every 10 seconds
var pi = 0
const letters = document.querySelectorAll(".anagram span");
const anagramElem = document.querySelector(".anagram");
const original = "klasse--serbest";

const p = [
    [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14],
    [11, 9, 13, 14, 12, 8, 6, 7, 2, 3, 4, 0, 1, 5, 10], 
    [11, 9, 13, 14, 12, 8, 6, 7, 2, 3, 0, 1, 5, 10, 4], 
    [10, 12, 13, 14, 6, 11, 9, 8, 4, 5, 1, 7, 3, 2, 0], 
    [10, 12, 13, 14, 6, 4, 5, 1, 11, 9, 7, 0, 2, 3, 8], 
    [10, 12, 13, 14, 6, 0, 2, 3, 4, 5, 7, 1, 9, 11, 8], 
    [11, 9, 13, 14, 6, 0, 2, 3, 4, 5, 1, 7, 12, 10, 8], 
    [11, 9, 13, 14, 6, 8, 2, 0, 12, 7, 10, 5, 3, 4, 1], 
    [8, 2, 0, 12, 6, 11, 10, 9, 13, 14, 7, 1, 5, 3, 4], 
    [11, 10, 9, 13, 14, 7, 1, 5, 3, 4, 6, 8, 2, 0, 12], 
    [11, 10, 9, 2, 13, 14, 8, 6, 7, 0, 12, 3, 4, 5, 1], 
    [1, 5, 3, 4, 6, 11, 2, 0, 9, 10, 7, 8, 12, 14, 13], 
    [12, 8, 13, 14, 7, 1, 5, 3, 4, 6, 11, 2, 0, 9, 10], 
    [0, 2, 3, 4, 5, 1, 6, 7, 11, 9, 13, 14, 12, 10, 8], 
    [4, 5, 0, 14, 6, 11, 9, 12, 10, 7, 2, 3, 8, 1, 13], 
    [4, 5, 0, 14, 6, 11, 9, 12, 10, 3, 7, 1, 2, 8, 13], 
    [13, 14, 6, 3, 5, 9, 1, 12, 7, 4, 0, 10, 2, 11, 8], 
    [0, 10, 2, 3, 4, 5, 6, 1, 9, 8, 11, 12, 7, 13, 14], 
    [0, 10, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 8, 9, 1], 
    [0, 10, 2, 3, 4, 5, 12, 8, 6, 7, 1, 9, 11, 13, 14], 
    [13, 14, 9, 10, 11, 12, 8, 6, 7, 0, 1, 2, 3, 4, 5], 
    [8, 9, 3, 4, 5, 1, 6, 0, 10, 2, 11, 7, 13, 12, 14], 
    [8, 9, 3, 4, 5, 1, 6, 12, 13, 14, 7, 0, 10, 2, 11], 
    [10, 2, 0, 6, 8, 9, 3, 4, 5, 1, 7, 11, 12, 13, 14], 
    [13, 14, 2, 10, 6, 8, 9, 3, 4, 5, 1, 7, 11, 12, 0], 
    [9, 11, 0, 6, 10, 2, 13, 14, 7, 8, 12, 3, 4, 5, 1], 
    [0, 9, 10, 11, 12, 6, 1, 2, 3, 4, 5, 8, 7, 13, 14], 
    [1, 5, 2, 3, 9, 6, 11, 12, 13, 14, 7, 4, 9, 10, 8], 
    [0, 5, 10, 11, 12, 6, 1, 2, 3, 4, 7, 11, 9, 13, 8], 
    [9, 10, 11, 12, 6, 0, 1, 2, 3, 4, 5, 7, 8, 14, 13], 
    [9, 10, 11, 12, 6, 0, 2, 3, 4, 5, 7, 1, 14, 8, 13]
];

const a = ["klasse--serbest", "bestes--asskler","bestes--asklers","rest-bessel-sak","rest-selbe-kass","rest-kasse-lebs","best-kassel-ers","best-sake-ressl","sake-brest-less","brest-less-sake","breasts--kessel","less-baker-sets","esst-less-baker","kassel--besters","sekt-beer-assls","sekt-beers-lass","st-seele-skrabs","krasse-lesbe-st","krasse--bestsel","krassees--lebst","sterbes--klasse","sessel-krab-set","sessel-est-krab","rak-sessel-best","star-sessel-bek","ebk-rast-sessel","kerbe-lasses-st","lease-best-sers","kerbe-lass-bess","erbe-klasse-sts"]

export function anagram(anagramElem) {

    anagramElem.innerHTML = a[pi];

    setTimeout(function() {
        anagramElem.innerHTML = a[pi + 1];
    }, 50)

    setTimeout(function() {
        anagramElem.innerHTML = a[pi + 2];
    }, 200)

    setTimeout(function() {
        anagramElem.innerHTML = original;
    }, 500)

    pi+=3;
    pi %= a.length;
    
    setTimeout(function () { anagram(anagramElem) }, 10000);

    
    // pi %= p.length;
}

function setTitle() {
    const w = "KLASSE--SERBEST"
    var t = "";
    for (var i = 0; i < p[0].length; i++) {
        t += w.charAt([p[pi][i]]);
    }
    document.title = t;
}

