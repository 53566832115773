

export function injectDoc(plane, url) {
    createIFrame(url, function (iframe) {
        // var blocker = document.createElement("div");
        // blocker.setAttribute("class", "blocker");
        // plane.appendChild(blocker);
        plane.appendChild(iframe);
        plane.classList.remove("loading")
    });
}

function callAjax(url, callback) {
    var xmlhttp;
    // compatible with IE7+, Firefox, Chrome, Opera, Safari
    xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            callback(xmlhttp.responseText);
        }
    }
    xmlhttp.open("GET", url, true);
    xmlhttp.send();
}

function inject(doc) {
    var cssLink = document.createElement("link")
    cssLink.href = "docs.css";
    cssLink.rel = "stylesheet";
    cssLink.type = "text/css";

    doc.getElementsByTagName("head")[0].appendChild(cssLink);
}

function createIFrame(url, callback) {
    callAjax(url, function (o) {
        var xmlString = o
            , parser = new DOMParser()
            , doc = parser.parseFromString(xmlString, "text/html");

        doc.getElementsByTagName("body")[0].setAttribute("style", "background: none !important; margin: 0 auto;");

        var iframe = document.createElement('iframe');

        iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(doc.documentElement.innerHTML);

        callback(iframe);
    });
} 