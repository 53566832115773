
function getRandomArbitrary(min, max) {
    return parseInt(Math.random() * (max - min) + min);
}

function randomizePosition(elems) {
    elems.forEach((elem) => {
        let x = getRandomArbitrary(2, 98);
        let y = getRandomArbitrary(20, 98);
        elem.style.setProperty("--x", x + "vw");
        elem.style.setProperty("--y", y + "vh");
        elem.setAttribute("x", x);
        elem.setAttribute("y", y);
    })
}

function handleDrag(elems) {
    elems.forEach((elem) => {
        elem.addEventListener("dragstart", (e) => {
            e.stopPropagation();
            e.dataTransfer.effectAllowed = "move";
            // Setup some dummy drag-data to ensure dragging
            e.dataTransfer.setData('text/plain', 'some_dummy_data');
        });

        elem.addEventListener("dragover", (e) => {
            e.stopPropagation();
            e.preventDefault();
        });

        elem.addEventListener("drag", (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.effectAllowed = "move";

            let p = e.target.parentNode;

            window.requestAnimationFrame(() => {
                p.style.setProperty("--x", (e.clientX / window.innerWidth * 100) + "vw")
                p.style.setProperty("--y", (e.clientY / window.innerHeight * 100) + "vh")
            });
        });

        elem.addEventListener("dragend", (e) => {
            e.preventDefault();
            e.stopPropagation();
        });
    });
}

export function batchify(selector) {
    let elems = document.querySelectorAll(selector);
    randomizePosition(elems);
    handleDrag(elems);

}