
import {anagram} from './anagram';
import {injectDoc} from './inject';
import {batchify} from './batch';



window.injectDoc = injectDoc;
window.anagram = anagram;
window.randomizePosition = batchify;

